import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Collapse from '@material-ui/core/Collapse'

const useStyles = makeStyles({
    root: {
        maxWidth: 370,
        backgroundColor: 'whitesmoke',
        margin: '20px'
    },
    media: {
        width: 370,
        height: 300,
    },
    title: {
        fontFamily: 'Nunito',
        fontSize: '1.5rem',
    },
    desc: {
        fontFamily: 'Nunito',
        fontWeight: 'bold',
        fontSize: '0.9rem',
    }
});

export default function ImgCard({ feature, checked }) {
    const classes = useStyles();
    return (
        <Collapse
            in={checked}
            {...(checked ? { timeout: 1200 } : {})}>
            <Card className={classes.root}>
                <CardMedia className="media" image={feature.imageUrl} title="Street Library" />
                <CardContent>
                    <Typography
                        className={classes.desc}>
                        {feature.desc}
                    </Typography>
                </CardContent>
            </Card>
        </Collapse>
    );
}