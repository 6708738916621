import React from 'react'

function PrivacyPolicy() {
    return (
        <div style={{ margin: '40px', lineHeight: '1.6' }}>
            <h4>Privacy Policy</h4>
            <p>Street Library recognize the importance of protecting the personal information collected from users in the operation of its services and taking reasonable steps to maintain the security, integrity and privacy of any information in accordance with this Privacy Policy. By submitting your information to Street Library you consent to the practices described in this policy. If you are
            less than 18 years of age, then you must first seek the consent of your parent or guardian prior to submitting any personal information.</p>
            <p>This Privacy Policy describes how Street Library collects and uses the personal information you provide to Street Library. It also describes the
             choices available to you regarding our use of your personal information and how you can access and update this information.</p>
            <div style={{ lineHeight: 2, fontWeight: 600 }}>
                How We Collect Information<br />
                Information You Provide<br />
                Information We Receive From Other Sources<br />
                Use of Your Information<br />
                Disclosure in Chat Rooms or Forums<br />
                Disclosure and Sharing of Your Information<br />
                Cross Border Transfers<br />
                Security<br />
                Cookies<br />
                Third Party Websites<br />
                Your Rights<br />
                Your California Data Privacy Rights<br />
                Deletion of Data<br />
                Disclosure of Personal Information<br />
                “Do Not Track” (for California and Delaware Residents)<br />
                How to Exercise Your Rights<br />
                FERPA (US Only)<br />
                Recourse<br />
                Updates to this Privacy Policy<br />
            </div>
            <h4>How We Collect Information</h4>
            <p>
                Street Library may collect personal information about you in the following ways: <br /><br />
            (1) directly from your verbal or written input (such as by consenting to receiving marketing emails or indirectly through third parties with whom we work closely (see below “Information We Receive from Other Sources”);<br /><br />
            (2) automatically through Street Library's website technologies including tracking online, such as by Web cookies (which are small text files created by websites that are stored on your computer), by smart devices, by combining data sets, by collecting data from a browser or device for use on a different computer or device, or by using algorithms to analyze a variety of data such as records of purchases, online behavioral data, or location data; or<br /><br />
            (3) by closed-circuit television (if visiting our premises).
            </p>
            <h4>Information You Provide</h4>
            <p>
                The types of information that Street Library collects directly from you or through your use of our websites and services may include the following depending on how you interact with Street Library:<br /><br />
            •	Contact details, such as your name, email address, postal address, username,  and telephone number;<br /><br />
            •	Internet Protocol ("IP") addresses used to connect your computer to the Internet;<br /><br />
            •	Educational and professional interests;<br /><br />
            •	Tracking codes such as cookies;<br /><br />
            •	Usernames and passwords;<br /><br />
            •	Payment information, such as a credit or debit card number;<br /><br />
            •	Comments, feedback, posts and other content you provide to Street Library (including through a Street Library website);<br /><br />            •	Communication preferences;
            •	Purchase and search history;<br /><br />
            •	Location-aware services, the physical location of your device in order to provide you with more relevant content for your location;<br /><br />
            •	Information about your personal preferences, and interests; <br /><br />
            •	Communications with other users of Street Library's services.<br /><br />
            In order to access certain content and to make use of additional functionality and features of Street Library's websites and services, we may ask you to register for an account by completing and submitting a registration form, which may request additional information.<br /><br />
            If you choose to register and sign in by using a third-party account (such as your Facebook account), the authentication of your login is handled by the third party. Street Library will collect your name, email address and any other information about your third-party account that you agree to share with us at the time you give permission for your Street Library account to be linked to your third-party account.
            </p>
            <h4>Information We Receive From Other Sources</h4>
            <p>Street Library may receive information about you if you use any of the websites we operate or the other services we
            provide. We also work closely with third parties (including, for example, business partners and sub-contractors in
            technical, payment and delivery services; advertising networks; data and analytics providers; academic institutions; journal owners
                , societies and similar organizations; search information providers, and credit reference agencies) from whom Street Library may receive information about you.</p>

            <h4>Use of Your Information</h4>
            <p>
                Depending on how you interact with Street Library, Street Library may use your personal information in the performance of any contract or transaction we enter into with you, to comply with legal obligations, or where Street Library has a legitimate business interest. Legitimate business purposes include but are not limited to one or all of the following: providing direct marketing and assessing the effectiveness of promotions and advertising; modifying, improving or personalizing our services, products and communications; detecting fraud; investigating suspicious activity and otherwise keeping our site safe and secure; and conducting data analytics. <br /><br />
            In addition, with your prior, explicit consent (where required), we may use your information in the following ways:<br /><br />
            •	To provide you with information about products and services that you request from us;<br /><br />
            •	To send you periodic catalogues from Street Library.<br /><br />
            •	To provide you with information about other products, events and services we offer that are either <br />
                (i) similar to those you have already purchased or inquired about, or <br />
                (ii) entirely new products, events and services.<br /><br />
            •	For internal business and research purposes to help enhance, evaluate, develop, and create Street Library websites (including usage statistics, such as "page views" on Street Library's websites and the products therein), products, and services.<br /><br />
            •	To notify you about changes or updates to our websites, products, or services.<br /><br />
            •	To provide, activate, and/or manage our services;<br /><br />
            •	For internal operations, including troubleshooting, data analysis, machine learning, testing, statistical, and survey purposes;<br /><br />
            •	To allow you to participate in interactive features of our service;<br /><br />
            •	For any other purpose that we may notify you of from time to time.<br /><br />
            Personal information will not be kept longer than is necessary for the purpose for which it was collected. This means that, unless information must be retained for legal or archival purposes, personal information will be securely destroyed, put beyond use or erased from Street Library's systems when it is no longer required or, where applicable, following a request from you to destroy or erase your personal information.
            </p>
            <h4>Disclosure and Sharing of Your Information</h4>
            <p>
                Street Library will not disclose to or share your personal information with any unaffiliated third party except as follows: <br /><br />
            •	Where necessary in connection with services provided by third parties (i) who provide us with a wide range of office, administrative, information technology, website and platform hosting, editing, production, payment, business management, analytics, content management, indexing, archiving, or marketing services; and (ii) who are required to comply with applicable privacy laws;<br /><br />
            •	Where you voluntarily provide information in response to an advertisement from a third party;<br /><br />
            •	Where third party such as an academic institution, school, employer, business or other entity has provided you with access to a Street Library product or service through an integration or access code, information may be shared regarding your engagement with the service or product, results of assessments taken and other information you input into the product or service;<br /><br />
            •	Where you participate in a program in which we partner with third parties, we may share your information with those third-party partners;<br /><br />
            •	Where Street Library is required to disclose personal information in response to lawful requests by public authorities and government agencies, including to meet national security or law enforcement requirements; to comply with a subpoena or other legal process; when we believe in good faith that disclosure is necessary to protect our rights, to enforce our Terms of Service, or to protect the rights, property or safety of our services, users or others; and to investigate fraud;<br /><br />
            •	Where all or substantially all of the business or assets of Street Library relating to our services are sold, assigned, or transferred to another entity;<br /><br />
            •	Where Street Library’s rights to publish, market and/or distribute a specific journal or other publication are transferred to another entity, and you have subscribed to or requested to receive electronic alerts related to that journal or publication;<br /><br />
            •	Where you have subscribed to journals, elected to receive electronic alerts about journals or your contribution to one of our journals has been accepted for publication, we may share your information with the journal owner or a society or organization associated with the journal; or<br /><br />
            •	Where you have attended an event, webinar, or conference, we may share your information with the sponsor of the activity; or<br /><br />
            •	Where, even if not described above, you have consented to such disclosure or Street Library has a legitimate interest in making the disclosure.<br /><br />

            Street Library also  may disclose navigational and transactional information in the form of anonymous, aggregate usage statistics and demographic information that does not reveal your identity or personal information
            </p>
            <p>
                <h4>Disclosure in Chat Rooms or Forums</h4>
            You should be aware that identifiable personal information--such as your name or e-mail address--that you voluntarily disclose and that is accessible to other users (e.g. on social media, forums, bulletin boards or in chat areas) could be collected and disclosed by others. Street Library cannot take any responsibility for such collection and disclosure.<br /><br />
                <h4>Cookies</h4>
            As is true of most websites, we gather certain information automatically. This information may include IP addresses, browser type, Internet service provider ("ISP"), referring/exit pages, the files viewed on our site (e.g., HTML pages, graphics, etc.), operating system, date/time stamp, and/or clickstream data to analyze trends in the aggregate and administer the site.<br /><br />
            Street Library and its partners use cookies or similar technologies to analyze trends, administer the website, track users' movements around the website, and to gather demographic information about our user base. You can control the use of cookies at the individual browser level, but if you choose to disable cookies, it may limit your use of certain features or functions on our website or services.<br /><br />
                <h4>Your Rights</h4>
            You have the right to make a written request to be informed whether we hold or process any of your personal information. In your written request, you may:<br /><br />
            •	Request that we provide you with details of your personal information that we process, the purpose for which it is processed, the recipients of such information, the existence of any automated decision making involving your personal information, and what transfer safeguards we have in place;<br /><br />
            •	Request that we rectify any errors in your personal information;<br /><br />
            •	Request that we delete your personal information if our continued processing of such information is not justified;<br /><br />
            •	Request that we transfer your personal information to a third party;<br /><br />
            •	Object to automated decision-making and profiling based on legitimate interests or the performance of a task in the public interest (in which event the processing will cease except where there are compelling legitimate grounds, such as when the processing is necessary for the performance of a contract between us);<br /><br />
            •	Object to direct marketing from us; and<br /><br />
            •	Object to processing for purposes of scientific, historical research and statistics.<br /><br />
            Where applicable under your local laws, we will not use your personal information for marketing purposes, nor disclose your information to any third parties, unless we have your prior consent, which we will seek before collecting your personal information. You can exercise your right to prevent such processing by checking certain boxes on the consent forms we use when collecting your personal information. If at any point you wish to review or change your preferences you can use the "opt-out" or unsubscribe mechanism or other means provided within the communications that you receive from us. Note that you may still receive transactional communications from Street Library.<br /><br />
            Links to Third-Party Websites<br /><br />
            Street Library's websites or services may include links to third-party websites. In using such links, please be aware that each third-party website is subject to its own privacy and data protection policies and is not covered by our Privacy Policy.<br /><br />
            Recourse<br /><br />
            Any comments, complaints or questions concerning this policy or complaints or objections about our use of your personal information should be addressed by directing your comments to Street Library's Data Protection Officers, whose contact information is found .<br /><br />
            Updates to this Privacy Policy<br /><br />
            Please note that Street Library’s Privacy Policy is reviewed periodically. Street Library reserves the right to update its Privacy Policy at any time without notice. Any changes to the Privacy Policy will be posted on this page and will become effective as of the Effective Date above. We encourage you to periodically review this page for the latest information on our privacy practices.<br /><br />
            </p>
        </div>
    )
}

export default PrivacyPolicy
