import React from 'react'
import './ContactScreen.css';
import Button from '@material-ui/core/Button';


function ContactScreen() {
    return (

        <div className="contact-container">
            <div className="left">
                <h1>Contact Us</h1>
                <h3>Better yet, see us in person!</h3>
                <p>We love our customers, so feel free to visit during normal business hours.</p>
                <Button
                    className="whatsapp-button"
                    variant="contained"
                    size="large"
                    href="https://api.whatsapp.com/send?phone=+919582830007">
                    Message Us on Whatsapp <i className="fab fa-whatsapp"></i>
                </Button>
                <h3>Street Librrary</h3>
                <p>Street Library (24*7), Nanda Enclave, B-Block, New Gopal Nagar, Najafgarh, Delhi, India</p>
                <p>
                    9582830007<br />
                    8882225160<br />
                    9728410109
                </p>
                <h3>Hours</h3>
                <p>08:00am - 07:00pm</p>
            </div>
            <div className="right">
                <h3>GET DIRECTIONS</h3>
                <iframe title="google Map"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.7109741408026!2d76.97031001440664!3d28.608446291922927!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d0f7babc4f9e9%3A0xc13553cf9a510edf!2sStreet%20Library%20(24*2A7)!5e0!3m2!1sen!2sin!4v1607142398909!5m2!1sen!2sin"></iframe>
            </div>
        </div>
    )
}

export default ContactScreen
