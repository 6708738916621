
import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router';
import './PayButton.css';
import PayButton from './PayButton';


function PaymentMethods({ userDetails }) {
    const classes = useStyles();
    return (
        <div className={classes.paymentMethodContainer}>
            <div>Get Subscription Now And Be A Part Of Street Library</div>
            <small className={classes.small}>(Investing in yourself is the best investment you can make.)</small>
            <PayButton userDetails={userDetails} />
        </div>
    )
}

const useStyles = makeStyles({
    paymentMethodContainer: {
        fontSize: '35px',
        fontWeight: 500,
        fontFamily: "Montserrat",
        lineHeight: 1.2,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        '@media (max-Width: 962px)': {
            fontSize: '27px'
        },
        '@media (max-Width: 600px)': {
            margin: '10px',
            fontSize: '20px'
        }
    },
    bold: {
        fontWeight: 900
    },
    small: {
        fontSize: '25px',
        '@media (max-Width: 962px)': {
            fontSize: '12px'
        }
    }
})

export default withRouter(PaymentMethods)


