import React, { useContext } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { UserContext } from '../App';



function FreeSeatConfirm({ seatNum, setError, close }) {
    const classes = useStyles();
    const context = useContext(UserContext);
    const history = useHistory();

    const handleConfirm = async () => {
        try {
            await axios.get(`/api/freeseat/${seatNum}`)
            close();
            window.location.reload();
        } catch (error) {
            if (error.response.status === 401) {
                context.dispatch({ type: 'USER', payload: {} });
                history.push('/login');
            }
            if (error.response.data.message) {
                setError(error.response.data.message);
            } else if (error.response.message) {
                setError(error.response.message);
            } else {
                setError("Please Check Your internet Connection");
            }
            close();
        }
    }

    function handleCancel() {
        close();
    }

    return (
        <div>
            <Dialog open={true} onClose={handleCancel} aria-labelledby="draggable-dialog-title">
                <DialogTitle id="draggable-dialog-title" className={classes.confirm}>
                    Confirmation
                </DialogTitle>
                <DialogContent >
                    <DialogContentText className={classes.text}>
                        Are you really want to Free this seat ? (please copy email address to set this user to another seat)
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleConfirm} color="primary">Confirm</Button>
                    <Button onClick={handleCancel} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles({
    confirm: {
        color: '#0d47a1',
        fontWeight: '900',
        fontSize: '30px',
        margin: '0',
    },
    text: {
        color: 'black',
        '@media (max-Width: 365px)': {
            fontSize: '14px'
        },
        '@media (max-Width: 340px)': {
            fontSize: '12px'
        }
    },
})

export default FreeSeatConfirm;
