import lib1 from '../Assets/lib1.jpg';
import libSeats from '../Assets/lib-seats.jpg'
import libParking from '../Assets/lib-parking.jpg'
import libSpace from '../Assets/lib-space.jpg'
import streetlib from '../Assets/streetlib.jpeg';
import locker from '../Assets/locker.jpeg';
import template from '../Assets/template.jpeg'
const features = [
    {
        imageUrl: streetlib,
        time: 1500,
        desc: 'Street Library'
    },
    {
        imageUrl: template,
        time: 1500,
        desc: 'Street library template'
    },
    {
        imageUrl: lib1,
        time: 1500,
        desc: 'Great Environment For Study'
    },
    {
        imageUrl: libSpace,
        time: 1500,
        desc: 'Comfortable seats'
    },
    {
        imageUrl: libSeats,
        time: 1500,
        desc: 'Wide Space Table, Comfortable Chairs'
    },
    {
        imageUrl: locker,
        time: 1500,
        desc: 'Lockers'
    },
    {
        imageUrl: libParking,
        time: 1500,
        desc: 'Parking Area With CCTV'
    },
]

export default features