import React, { useState, useEffect, useContext } from 'react'
// import Alert from '@material-ui/lab/Alert';
import AdminSeatsArrangement from './AdminSeatsArrangement';
import axios from 'axios';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import MessageBox from '../../components/MessageBox';
import { CircularProgress } from '@material-ui/core';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';
import RefundBox from '../../components/RefundUser/RefundBox';

function AdminScreen() {
    // const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(true);
    const [seatsStatus, setSeatsStatus] = useState([]);
    const [userStatus, setUserStatus] = useState([]);
    const [refreshMsg, setRefreshMsg] = useState('');
    const classes = useStyles();
    const history = useHistory();
    const { state, dispatch } = useContext(UserContext);
    const isAdmin = state ? state.isAdmin : false;

    if (!isAdmin) {
        history.push('/');
    }

    async function refreshHandler() {
        try {
            const res = await axios.put('api/admin/refresh');
            if (res.data.success) {
                setRefreshMsg('Seats are updated successfully ✔ ');
            }
            setLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: 'USER', payload: {} });
                history.push('/login');
            }
            if (error.response.data.message) {
                setRefreshMsg(error.response.data.message);
            } else if (error.response.message) {
                setRefreshMsg(error.response.message);
            } else {
                setRefreshMsg("Please Check Your internet Connection");
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await axios('/api/admin/seats');
                // console.log(res.data);
                setSeatsStatus(res.data.seatsStatus);
                setUserStatus(res.data.userStatus);
                setLoading(false);

            } catch (error) {
                if (error.response.status === 401) {
                    dispatch({ type: 'USER', payload: {} });
                    history.push('/login');
                }
                setLoading(false);
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [])
    return (
        <div className="book-seat">
            {refreshMsg.length !== 0 && <MessageBox msg={refreshMsg} reload />}
            <div className={classes.container}>
                <Button variant="contained" color="primary" className={classes.btn} onClick={refreshHandler} >Refresh</Button>
                <h1 style={{ marginBottom: '15px' }}>Admin</h1>
                <Link to="/admin/offlineuser">
                    <Button variant="contained" color="primary" className={classes.btn}  >Offline user</Button>
                </Link>
            </div>
            {/* {errorMsg.length !== 0 && <Alert severity="error" variant="filled" >{errorMsg}</Alert>} */}
            <div style={{ marginBottom: '20px' }}>
                <RefundBox style={classes.btn} />
            </div>
            {
                loading ? <div className={classes.loading}><CircularProgress size="10rem" /></div> : (
                    <AdminSeatsArrangement seatsStatus={seatsStatus} userStatus={userStatus} />
                )}
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        display: 'flex',
        width: '60%',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: '20px 0 20px',
        '@media (max-Width: 830px)': {
            width: '95%',
        }
    },
    bold: {
        fontWeight: 900
    },
    btn: {
        backgroundImage: 'linear-gradient(to right, #ff4b1f 0%, #ff9068  51%, #ff4b1f  100%)',
        transition: '0.5s',
        backgroundSize: '200% auto',
        boxShadow: '0 0 5px #eee',
        '&:hover': {
            color: '#fff',
            backgroundPosition: 'right center',
            textDecoration: 'none'
        },
        '@media (max-Width: 530px)': {
            padding: '5px 10px'
        }
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }

})

export default AdminScreen
