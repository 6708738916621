import React from 'react'

function AboutUs() {
    return (
        <div style={{ minHeight: '70vh', margin: '40px' }}>
            <h1>About Us</h1>
            <p>
                Since 2018, we have been helping people to devlop their skills and knowledge they need to succeed.
                Our Ambience is bridging the higher education in new and innovative ways to encrich the learning experience.
                We encourage learning to be continues, life long experiance an integral, essential part of every stage of building career
                we provide quiet areas of studying as well as command areas for group study and collaboration.
                we engage people in lifelong learning.
            </p>
        </div>
    )
}

export default AboutUs
