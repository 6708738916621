import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import { Button, CircularProgress } from '@material-ui/core';
import DialogForm from '../../components/DialogForm'
import './SingleSeat.css';
// import MessageBox from '../../components/MessageBox';
import FreeSeatConfim from '../../components/FreeSeatConfim';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';


function SingleSeatDetail({ match }) {
    const { seatNum } = match.params;

    const [loading, setLoading] = useState(true);
    const [errorMsg, setErrorMsg] = useState('');
    const [seatDetail, setSeatDetail] = useState({});
    const [seatStatus, setSeatStatus] = useState();
    const [setFreeSeat, setSetFreeSeat] = useState(false);
    const [free, setFree] = useState(false);
    const history = useHistory();
    const { state, dispatch } = useContext(UserContext);
    const isAdmin = state ? state.isAdmin : false;

    function freeSeatHandler() {
        setFree(true);
    }

    async function blockSeatHandler() {
        setLoading(true);
        if (!isAdmin) {
            history.push('/');
        }

        try {
            await axios.get(`/api/admin/blockseat/${seatNum}`);
            setSeatStatus(!seatStatus);
            setLoading(false);
        }
        catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: 'USER', payload: {} });
                history.push('/login');
            }
            if (error.response.data.message) {
                setErrorMsg(error.response.data.message);
            } else if (error.response.message) {
                setErrorMsg(error.response.message);
            } else {
                setErrorMsg("Please Check Your internet Connection");
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        axios(`/api/admin/seat/${seatNum}`)
            .then(res => {
                setSeatDetail(res.data);
                setSeatStatus(res.data.seatStatus);
                setLoading(false);
            })
            .catch(error => {
                if (error.response.status === 401) {
                    dispatch({ type: 'USER', payload: {} });
                    history.push('/login');
                }
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                } else if (error.response.message) {
                    setErrorMsg(error.response.message);
                } else {
                    setErrorMsg("Please Check Your internet Connection");
                }
                setLoading(false);
            })
        // eslint-disable-next-line
    }, [seatNum])

    return (
        <div className="singleSeat-Container">
            {setFreeSeat && <DialogForm seatNum={seatNum} closeDialogForm={() => setSetFreeSeat(false)} />}
            {free && <FreeSeatConfim seatNum={seatNum} setError={(data) => setErrorMsg(data)} close={() => setFree(false)} />}
            <div className="singleSeat-header">
                {
                    !loading &&
                    <>
                        <Button variant="contained" color="primary" disabled={seatDetail.user ? true : false} onClick={() => setSetFreeSeat(true)} > Set Seat</Button>
                        <h1>Seat Detail</h1>
                        <Button variant="contained" color="secondary" disabled={seatDetail.user ? false : true} onClick={freeSeatHandler}>Free Seat <i className="far fa-trash-alt"> </i></Button>
                    </>
                }

            </div>
            {errorMsg.length !== 0 && <Alert severity="error" variant="filled" >{errorMsg}</Alert>}
            {
                loading ? <div><CircularProgress size="10rem" /> </div> : (!seatDetail.user || seatDetail.user === null) ?
                    <>
                        <Button color={seatStatus ? "secondary" : "primary"} variant="contained" onClick={blockSeatHandler}>{seatStatus ? 'Unblock' : 'block'}</Button>
                        <h1 className="free-Seat">Seat Is Not Occupied By Anyone</h1>
                    </>
                    :
                    (
                        <div className="table-container">
                            <table >
                                <thead>
                                    <tr>
                                        <th>S.No.</th>
                                        <th>Seat Number</th>
                                        <th>{seatNum}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        seatDetail.user.adminName && (
                                            <>
                                                <td>0</td>
                                                <td>Admin Name</td>
                                                <td>{seatDetail.user.adminName}</td>
                                            </>
                                        )
                                    }
                                    <tr>
                                        <td>1</td>
                                        <td>User Id</td>
                                        <td>{seatDetail.user._id}</td>
                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>User Name</td>
                                        <td>{seatDetail.user.fname}{' '}{seatDetail.user.lname}</td>
                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>User Email</td>
                                        <td>{seatDetail.user.email}</td>
                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>User Phone Number</td>
                                        <td>{seatDetail.user.phone}</td>
                                    </tr>
                                    <tr>
                                        <td>5</td>
                                        <td>Transaction-ID</td>
                                        <td>{seatDetail.user.TXNID ? seatDetail.user.TXNID : 'By Cash'}</td>

                                    </tr>
                                    <tr>
                                        <td>6</td>
                                        <td>Payment Date</td>
                                        <td>{new Date(seatDetail.user.payDate).toString().slice(0, 25)}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    )}
        </div >
    )
}
export default SingleSeatDetail
