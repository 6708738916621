import React, { useState, useEffect, useContext } from 'react'
import { Avatar, Button } from '@material-ui/core';
import admin from '../../Assets/images.png'
import './notificationScreen.css';
import { makeStyles } from '@material-ui/core/styles';
import AddNotification from './AddNotification';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { UserContext } from '../../App';


function NotificationScreen() {
    const context = useContext(UserContext);
    const [addNoti, setAddNoti] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [deleteMsg, setDeleteMsg] = useState('');
    const classes = useStyles();
    const isAdmin = context.state ? context.state.isAdmin : false;

    async function deleteHandler(id) {
        setLoading(true);
        try {
            const res = await axios.delete(`/api/delete/notification/${id}`);
            if (res.data.success) {
                setDeleteMsg('Notification Deleted successfully.')
            }
            setLoading(false);
        } catch (error) {
            if (error.response.data.message) {
                setDeleteMsg(error.response.data.message);
            } else if (error.response.message) {
                setDeleteMsg(error.response.message);
            } else {
                setDeleteMsg("Please Check Your internet Connection");
            }
            setLoading(false);
        }
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await axios('/api/notification');
                setNotifications(res.data);
                setLoading(false);
            } catch (error) {
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                } else if (error.response.message) {
                    setErrorMsg(error.response.message);
                } else {
                    setErrorMsg("Please Check Your internet Connection");
                }
                setLoading(false);
            }
        }
        fetchData();
    }, [])
    return (
        <div className="noti-container">
            {addNoti && <AddNotification closeAddNoti={() => setAddNoti(false)} />}
            {errorMsg.length !== 0 && <Alert severity="error" variant="filled" className={classes.alert} >{errorMsg}</Alert>}
            {deleteMsg.length !== 0 && <MessageBox msg={deleteMsg} reload />}
            {loading ? <div className={classes.loading}><CircularProgress size="10rem" /> </div> : (
                <>
                    <div className="noti-header-container">
                        <h1 className="noti-header" >Notifications</h1>
                        {
                            isAdmin && <Button variant="contained" color="primary" onClick={() => setAddNoti(true)} >Add Notification</Button>
                        }
                    </div>
                    {notifications.length === 0 ? <h1 className="no-Notification">No Updates Yet</h1> :
                        notifications.map(notification => (
                            <div className="noti" key={notification.createdAt}>
                                <div className="noti-sub">
                                    <Avatar className={classes.large} alt={"notification"} src={admin} />
                                    <div>
                                        <div className="noti-title">{notification.message}</div>
                                        <div className="noti-date">
                                            <i className="fas fa-clock"></i>
                                            {new Date(notification.createdAt).toString().slice(0, 25)}
                                            {isAdmin && <div style={{ display: 'inline' }} > | Publish By- {notification.adminName}</div>}
                                        </div>
                                    </div>
                                </div>
                                {isAdmin &&
                                    <div onClick={() => deleteHandler(notification._id)}><i className="fas fa-times cross"></i></div>
                                }
                            </div>))
                    }
                </>
            )}
        </div >
    )
}

const useStyles = makeStyles((theme) => ({
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginLeft: '5px',
        '@media (max-Width: 1000px)': {
            width: theme.spacing(6),
            height: theme.spacing(6),
        }
    },
}));


export default NotificationScreen
