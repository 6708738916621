import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';



function ConfirmDialog({ seatNum, cancelSeat, Error }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const history = useHistory();

    const handleConfirm = async () => {
        setOpen(false);

        try {
            const res = await axios.patch(`/api/seat/${seatNum}`)
            if (res.data.success) {
                history.replace('/user');
            }
        } catch (error) {
            if (error.response.data.message) {
                Error(error.response.data.message);
            } else if (error.response.message) {
                Error(error.response.message);
            } else {
                Error("Please Check Your internet Connection");
            }
        }
    }

    function handleCancel() {
        cancelSeat();
        setOpen(false);
    }

    return (
        <div>
            <Dialog open={open} onClose={handleCancel} aria-labelledby="draggable-dialog-title">
                <DialogTitle id="draggable-dialog-title" className={classes.confirm}>
                    Confirmation
                </DialogTitle>
                <DialogContent >
                    <DialogContentText className={classes.text}>
                        You have selected Seat Number<strong className={classes.bold}> {seatNum}</strong>.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={handleConfirm} color="primary">Confirm</Button>
                    <Button onClick={handleCancel} color="primary">Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

const useStyles = makeStyles({
    confirm: {
        color: '#0d47a1',
        fontWeight: '900',
        fontSize: '30px',
        margin: '0',
    },
    text: {
        color: 'black',
        '@media (max-Width: 365px)': {
            fontSize: '14px'
        },
        '@media (max-Width: 340px)': {
            fontSize: '12px'
        }
    },
})

export default ConfirmDialog;
