import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { CircularProgress } from '@material-ui/core';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

export default function OfflineUserScreen() {
    const [email, setEmail] = useState('');
    const [seatNum, setSeatNum] = useState('');
    const [loading, setLoading] = useState(false);
    const [resMsg, setResMsg] = useState('');
    const history = useHistory();
    const { state, dispatch } = useContext(UserContext);
    const isAdmin = state ? state.isAdmin : false;

    if (!isAdmin) {
        history.push('/');
    }


    async function setSeatHandler(e) {
        e.preventDefault();

        if (email.length === 0 || seatNum.length === 0) {
            return;
        }
        try {
            setLoading(true);
            const res = await axios.post('/api/offlineuser', { email, seatNum })
            if (res.data.success) {
                setResMsg('Seat is Set to user successfully 👍.');
            }
            setLoading(false);
        } catch (error) {
            if (error.response.status === 401) {
                dispatch({ type: 'USER', payload: {} });
                history.push('/login');
            }
            if (error.response.data.message) {
                setResMsg(error.response.data.message);
            } else if (error.response.message) {
                setResMsg(error.response.message);
            } else {
                setResMsg("Please Check Your internet Connection");
            }
            setLoading(false);
        }

    }

    return (
        <div style={{ height: 'calc(80vh - 80px)' }}>
            <DialogTitle id="form-dialog-title">Offline User</DialogTitle>
            {resMsg.length !== 0 && <MessageBox msg={resMsg} reload />}
            {
                loading ? <div><CircularProgress size="10rem" /> </div> : (
                    <DialogContent>
                        <DialogContentText>Please Provide a registered email Id and a Free Seat</DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                            value={email}
                            onChange={(e) => setEmail(e.target.value)} />
                        <TextField
                            margin="dense"
                            id="name"
                            label="Seat No."
                            type="text"
                            fullWidth
                            value={seatNum}
                            onChange={(e) => setSeatNum(e.target.value)} />
                    </DialogContent>
                )
            }
            <DialogActions>
                <Button onClick={setSeatHandler} color="primary">Confirm</Button>
            </DialogActions>
        </div>
    );
}
