
import React, { useState, useEffect, useContext } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import { deepOrange } from '@material-ui/core/colors';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';


export default function SignupScreen() {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [success, setSuccess] = useState('');
    const classes = useStyles();
    const history = useHistory();
    const { state } = useContext(UserContext);

    let passwordNotMatch, notValidEmail, notValidPhone, notValidName, notValidSurname;

    if (confirmPassword.length !== 0 && password !== confirmPassword) {
        passwordNotMatch = true;
    } else {
        passwordNotMatch = false;
    }

    // eslint-disable-next-line no-useless-escape
    if (email.length !== 0 && !email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        notValidEmail = true;
    } else {
        notValidEmail = false;
    }

    if (phone.length !== 0 && !phone.match(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/)) {
        notValidPhone = true;
    } else {
        notValidPhone = false;
    }

    if (fname.match(/[^\w\s]/g) || fname.match(/[0-9]/g)) {
        notValidName = true;
    } else {
        notValidName = false;
    }
    if (lname.match(/[^\w\s]/g) || lname.match(/[0-9]/g)) {
        notValidSurname = true;
    } else {
        notValidSurname = false;
    }

    function setStateToZero() {
        setEmail('');
        setPhone('');
        setPassword('');
        setConfirmPassword('');
        setFname('');
        setLname('');
    }

    async function submitHandler(e) {
        e.preventDefault();

        if (passwordNotMatch || notValidEmail || notValidPhone) {
            return;
        }

        const newUser = {
            fname: fname.trim(),
            lname: lname.trim(),
            email,
            phone,
            password,
        }
        setLoading(true);
        try {
            const res = await axios.post('/api/signup', newUser);
            setLoading(false);
            setErrorMsg('');
            setSuccess(`Great ${res.data.user.fname.toUpperCase()}, You Registered Successfully 🎊`)
            setStateToZero();
        }
        catch (error) {
            if (error.response.data.message) {
                setErrorMsg(error.response.data.message);
            } else if (error.response.message) {
                setErrorMsg(error.response.message);
            } else {
                setErrorMsg("Please Check Your internet Connection");
            }
            setLoading(false);
            setStateToZero();
        }

    }

    useEffect(() => {
        if (state.fname) {
            history.push('/');
        }
        // eslint-disable-next-line
    }, [])


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            {success.length !== 0 && <MessageBox msg={success} redirectLogin />}
            <Grid item xs={false} sm={false} md={6} className="image" />
            <Grid item xs={12} sm={12} md={6} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign up
                    </Typography>
                    {errorMsg.length !== 0 && <Alert severity="error" variant="filled" className={classes.alert} >{errorMsg}</Alert>}
                    {loading ?
                        <div className={classes.loading}><CircularProgress size="10rem" /> </div>
                        : (
                            <form className={classes.form} onSubmit={submitHandler}>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            autoComplete="fname"
                                            name="firstName"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            error={notValidName && true}
                                            helperText={notValidName && "Invalid Name"}
                                            id="firstName"
                                            label="First Name"
                                            autoFocus
                                            value={fname}
                                            onChange={e => setFname(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="lastName"
                                            error={notValidSurname && true}
                                            helperText={notValidSurname && "Invalid Surname"}
                                            label="Last Name"
                                            name="lastName"
                                            autoComplete="lname"
                                            value={lname}
                                            onChange={e => setLname(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            error={notValidEmail && true}
                                            helperText={notValidEmail && "Invalid Email Address."}
                                            id="email"
                                            label="Email Address"
                                            name="email"
                                            autoComplete="email"
                                            value={email}
                                            onChange={e => setEmail(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            error={notValidPhone && true}
                                            helperText={notValidPhone && "Invalid Phone Number."}
                                            name="phoneNo"
                                            label="Phone Number"
                                            type="text"
                                            id="phoneNo"
                                            value={phone}
                                            onChange={e => setPhone(e.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            name="password"
                                            label="Password"
                                            error={password.length !== 0 && password.length < 8 && true}
                                            helperText={password.length !== 0 && password.length < 8 && "Password Must Contain Atleast 8 Characters."}
                                            type={showPassword ? 'text' : 'password'}
                                            id="password"
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <div onClick={() => setShowPassword(!showPassword)} className="eye-btn" >
                                                            {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                                        </div>
                                                    </InputAdornment>
                                                ),
                                            }}
                                            value={password}
                                            onChange={e => setPassword(e.target.value)} />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            error={passwordNotMatch && true}
                                            helperText={passwordNotMatch && "Passwords doest not match."}
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            type="password"
                                            id="confirmPassword"
                                            value={confirmPassword}
                                            onChange={e => setConfirmPassword(e.target.value)} />
                                    </Grid>
                                </Grid>
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                    Sign Up
                                </Button>
                                <Grid container justify="flex-end">
                                    <Grid item>
                                        <Link href="/login" variant="body2">
                                            Already have an account? Sign in
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }
                </div>
            </Grid>
        </Grid>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '90vh'
    },
    paper: {
        margin: theme.spacing(7, 8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-Width: 600px)': {
            margin: theme.spacing(0, 2),
            // height: '80vh',
            justifyContent: 'unset'
        }

    },
    avatar: {
        width: '75px',
        height: '75px',
        margin: theme.spacing(1),
        backgroundColor: deepOrange[500],
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        margin: '10px 0',
        padding: '0px 15px',
        '@media (max-Width: 400px)': {
            fontSize: '11px'
        }
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
}));