import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import Alert from '@material-ui/lab/Alert';
import MessageBox from '../../components/MessageBox';
import { CircularProgress } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(80vh - 80px)',
        display: 'flex',
        alignItems: 'center'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    }
}));

export default function ForgotPasswordScreen() {
    const [email, setEmail] = useState('');
    const [isMailSend, setIsMailSend] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const classes = useStyles();

    let notValidEmail;
    // eslint-disable-next-line no-useless-escape
    if (email.length !== 0 && !email.match(/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i)) {
        notValidEmail = true;
    } else {
        notValidEmail = false;
    }


    async function submitEmailHandler(e) {
        e.preventDefault();
        if (email.length === 0) {
            return;
        }
        try {
            setLoading(true);
            const res = await axios.post('/api/forgotpassword', { email });
            if (res.data.status === 'success') {
                setIsMailSend(true);
            }
            setLoading(false);
        } catch (error) {
            if (error.response.data.message) {
                setErrorMsg(error.response.data.message);
            } else if (error.response.message) {
                setErrorMsg(error.response.message);
            } else {
                setErrorMsg("Please Check Your internet Connection");
            }
            setLoading(false);
        }
    }

    return (
        <div className={classes.container}>
            <Container component="main" maxWidth="xs" >
                {isMailSend && <MessageBox msg={"OTP sended successfully. Please Check Email."} resetpassword />}
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}> <i className="fas fa-unlock-alt"></i> </Avatar>
                    <Typography component="h1" variant="h5">
                        Forgot Password
                </Typography>
                    {errorMsg.length !== 0 && <Alert severity="error" variant="filled" className={classes.alert} >{errorMsg}</Alert>}
                    {
                        loading ? <div className={classes.loading}><CircularProgress size="10rem" /> </div> : (
                            <form className={classes.form} noValidate>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    error={notValidEmail && true}
                                    helperText={notValidEmail && "Invalid Email Address."}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={submitEmailHandler}
                                    className={classes.submit}>
                                    Reset Password
                    </Button>
                            </form>
                        )
                    }

                </div>
            </Container>
        </div>
    );
}