import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.css'

function Footer() {
    return (
        <div className="footer">
            <span>Copyright © 2020 Street library - All Rights Reserved.</span>
            <span className="formality">
                <Link className="termsAndCondition" to="/termsAndCondition">Terms of Use</Link>
                <Link className="termsAndCondition" to="/PrivacyPolicy">Privacy Policy</Link>
                <Link className="termsAndCondition" to="/aboutUs">About Us</Link>
            </span>
        </div>
    )
}


export default Footer
