import React from 'react';
import './PayButton.css';
// import MonthSelector from './MonthSelector';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';

function loadScript() {
  return new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

function PayButton({ userDetails }) {
  // let months = 1;
  const classes = useStyles();

  async function displayRazor() {
    const res = await loadScript();

    if (!res) {
      alert('Razorpay SDK failed to load. Are You online?');
      return;
    }

    const data = await axios.post('/api/razorpay', {});

    var options = {
      key: 'rzp_live_oeGlAbUdy2yDdD',
      // "key": 'rzp_test_MsOwU8PCmyNzYT',
      amount: data.data.amount,
      currency: data.data.currency,
      name: 'Street Library',
      description: 'Subscription fee',
      image: '/icon/favicon.svg',
      order_id: data.data.id,
      callback_url: `/api/razorpay/callback/`,
      prefill: {
        name: userDetails.fname,
        email: userDetails.email,
        contact: userDetails.phone,
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  return (
    <div className={classes.payContainer}>
      <div className='container' onClick={displayRazor}>
        <div className='left-side'>
          <div className='card'>
            <div className='card-line'></div>
            <div className='buttons'></div>
          </div>
          <div className='post'>
            <div className='post-line'></div>
            <div className='screen'>
              <div className='dollar'>$</div>
            </div>
            <div className='numbers'></div>
            <div className='numbers-line2'></div>
          </div>
        </div>
        <div className='right-side'>
          <div className='new'>Pay Now</div>
          <svg
            className='arrow'
            xmlns='http://www.w3.org/2000/svg'
            width='512'
            height='512'
            viewBox='0 0 451.846 451.847'
          >
            <path
              d='M345.441 248.292L151.154 442.573c-12.359 12.365-32.397 12.365-44.75 0-12.354-12.354-12.354-32.391 0-44.744L278.318 225.92 106.409 54.017c-12.354-12.359-12.354-32.394 0-44.748 12.354-12.359 32.391-12.359 44.75 0l194.287 194.284c6.177 6.18 9.262 14.271 9.262 22.366 0 8.099-3.091 16.196-9.267 22.373z'
              data-original='#000000'
              class='active-path'
              data-old_color='#000000'
              fill='#cfcfcf'
            />
          </svg>
        </div>
      </div>
      {/* <MonthSelector months={(data) => months = data} /> */}
    </div>
  );
}

const useStyles = makeStyles({
  payContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '10px',
    gap: '25px',
    // justifyContent: 'space-between'
  },
});

export default PayButton;
