import React from 'react'
import { showSeats } from './seats';

function SeatArrangement({ onSeatClickHandler, seatsStatus }) {
    // showSeats.row5.map(seat => console.log(seat.seatNum));
    return (
        <div className="seats-container">
            <div className="wash-room-container">
                <div className="wash-room">
                    Wash Room
                    </div>
            </div>
            <div className="row0-container">
                {showSeats.row0.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row0 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row1-container" >
                {showSeats.row1.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row1 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="counter-container">
                <div className="counter">
                    Counter
                    </div>
            </div>
            <div className="row10-container">
                {showSeats.row10.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row10 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row9-container">
                {showSeats.row9.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row9 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row8-container">
                {showSeats.row8.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row8 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row7-container">
                {showSeats.row7.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row7 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row6-container">
                {showSeats.row6.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row6 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row5-container">
                {showSeats.row5.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row5 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row4-container">
                {showSeats.row4.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row4 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row3-container">
                {showSeats.row3.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row3 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row2-container">
                {showSeats.row2.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && 'occupied'} row2 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
        </div>
    )
}

export default SeatArrangement
