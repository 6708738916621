import React, { useState, useContext } from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import user from '../../Assets/user-2.jpg';
import admin from '../../Assets/images.png'
import './Navbar.css';
import { Badge } from '@material-ui/core';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';



const useStyles = makeStyles((theme) => ({
  small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
    marginLeft: '5px',
    '@media (max-Width: 1000px)': {
      width: theme.spacing(4),
      height: theme.spacing(4),
    }
  },
}));

export default function Navbar() {
  const { state, dispatch } = useContext(UserContext);
  const userDetails = state;

  const [clicked, setClicked] = useState(false);
  const [navbar, setNavbar] = useState(false);
  const classes = useStyles();
  const history = useHistory();


  function handleClick() {
    setClicked(!clicked);
  }
  function handleNavbar() {
    if (window.scrollY >= 30) {
      setNavbar(true);
    }
    else {
      setNavbar(false);
    }
  }
  window.addEventListener('scroll', handleNavbar);

  async function logoutHandler() {
    await axios.get('/api/logout');
    dispatch({ type: "USER", payload: {} })
    history.replace('/login');
    setClicked(!clicked);
  }

  return (
    <nav className={navbar ? "NavbarItems" : "NavbarItems"}>
      <Link to="/"><h1 className="navbar-logo">Street Library<i className="fas fa-book-open"></i></h1></Link>

      <div className="menu-icon" onClick={handleClick}>
        {/* <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i> */}
        {
          clicked ? <CloseIcon fontSize="large" sx={{ color: 'white', }} /> :
            <MenuIcon fontSize="large" sx={{ color: 'white', }} />
        }
      </div>
      <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
        <li><Link to="/" className="navbar-btn" onClick={handleClick} >Home</Link></li>
        <li><Link to="/contactus" className="navbar-btn" onClick={handleClick} >Contact Us</Link></li>
        <li><Link to="/notification" className="navbar-btn user" onClick={handleClick} >
          <Badge variant="dot" color="error"> Notification</Badge>
        </Link></li>
        {userDetails.fname ?
          <>
            {userDetails.isAdmin ?
              <li><Link to="/admin" className="navbar-btn user" onClick={handleClick} >{userDetails.fname}<Avatar className={classes.small} alt={userDetails.fname} src={admin} /> </Link></li>
              :
              <li><Link to="/user" className="navbar-btn user" onClick={handleClick} >{userDetails.fname} <Avatar className={classes.small} alt={userDetails.fname} src={user} /> </Link></li>
            }
            <li><Link to="/login" className="signup-btn signup-btn-mobile" onClick={logoutHandler} >Log Out</Link></li>
          </>
          : (
            <>
              <li><Link to="/login" className="navbar-btn" onClick={handleClick} >Log In</Link></li>
              <li><Link to="/signup" className="signup-btn signup-btn-mobile" onClick={handleClick} >Sign Up</Link></li>
            </>
          )
        }
      </ul>
    </nav>
  )
}
