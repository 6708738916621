import React from 'react'

function TermsOfUse() {
    return (
        <div style={{ margin: '40px', lineHeight: '1.6' }}>
            <h4>Terms of Use</h4>
            <p>The following Terms of Use govern use by all Users of Street Library
            (or any successor thereto) and the electronic edition of the products subscribed to
            by an Authorized User or the Authorized User's institution (the “Customer”).
            Please read the following carefully because by using Street Library you are agreeing to
               abide by these Terms of Use.</p>
            <p>Your institution may have a license which permits you additional uses; contact your institution's librarian/administrator for more information. If you or your institution entered into a license with Street Library (the “License”),
             the terms of the License control in the event of any discrepancy between it and these Terms of Use.</p>

            <h4>Copyright</h4>
            <p>The Electronic Products, Street Library and the Intellectual Property Rights contained therein are protected by law.
              As a User, you have certain rights specified below; all other rights are reserved.</p>

            <h4>Definitions</h4>
            <div >
                <p>1. <span style={{ fontWeight: 600 }}>Authorized Users</span> – As defined in your applicable license.</p>
                <p>2. <span style={{ fontWeight: 600 }}>Electronic Products</span>  - All products, services and content available in Street Library.</p>
                <p>3. <span style={{ fontWeight: 600 }}>Intellectual Property Rights </span> – These rights include, without limitation, patents, trademarks, trade names, design rights, copyright (including rights in computer software), database rights, rights in know-how and other intellectual property
                    rights, in each case whether registered or unregistered, which may subsist anywhere in the world.</p>
                <p>4. <span style={{ fontWeight: 600 }}>Licensed Electronic Products</span> – The Electronic Products which the Customer has licensed.</p>
                <p>5. <span style={{ fontWeight: 600 }}>Users</span> – Users of Street Library regardless of whether they are Authorized Users or Customer.</p>
                <p>6. <span style={{ fontWeight: 600 }}>Street Library </span>- The online service (or any successor thereto) available from Street Library or its affiliates including all products, services and features offered via the service. Certain products and services may be delivered from other platforms.
                    The terms and conditions hereof are equally applicable to those products and services.</p>
            </div>
            <h4>Rules of Use</h4>
            <p>
                A. To make Street Library and the Electronic Products useful, the following rules apply: <br />
            1.	Users may browse all Tables of Content for all Electronic Products on Street Library, regardless of what Licensed Electronic Products the Customer subscribes to, and search the entire database of Tables of Content and abstracts. For most publications, Users may browse the abstracts as well.<br /><br />
            2.	Customers and their Authorized Users will have access to the full text of the Licensed Electronic Products covered by the applicable License. Customers and their Authorized Users may download, search, retrieve, display and view, copy and save to a secure network or other electronic storage media and store or print out single copies of individual articles or items for their own personal use, scholarly, educational or scientific research or internal business use. Customers and Authorized Users may also transmit such material to a third-party colleague in hard copy or electronically for personal use or scholarly, educational, or scientific research or professional use but in no case for re-sale, systematic distribution, e.g. posting on a listserv, network (including scientific social networks) or automated delivery, or for any other use (including distribution through social networking websites and scholarly collaboration networks, except for those that have agreed and solely in accordance therewith). In addition, Authorized Users have the right to use, with appropriate credit, figures, tables and brief excerpts from individual articles in the Licensed Electronic Product(s) in their own scientific, scholarly and educational works. Please note that these rights do not extend to the use of material, images or figures that are separately listed as the copyright of a third party.<br /><br />
            3.	All Users have the option to create a My Profile Page which will allow them to create links to Electronic Product(s), articles of interest and search criteria which may be reused by them and manage their custom e-mail alerting services. In order to do so, Users must register. During the registration process, Users will select and register a user name and password which they must keep confidential and not disclose to or share with anyone else.<br /><br />
            B. Except as provided above or with respect to material published on an open access basis, Users may not copy, distribute, transmit or otherwise reproduce, sell or resell material from Electronic Product(s); store such material in any form or medium in a retrieval system; download and/or store an entire issue of an Electronic Product or its equivalent; or transmit such material, directly or indirectly, for use in any paid service such as document delivery or list serve, or for use by any information brokerage or for systematic distribution, whether or not for commercial or non-profit use or for a fee or free of charge.<br /><br />
            C. Users agree not to remove, obscure or modify any copyright or proprietary notices, author attribution or any disclaimer as they appear on Street Library. Users may not integrate material from the Electronic Product(s) with other material or otherwise create derivative works in any medium or override any security feature or exclusionary protocol. However, brief quotations for purposes of comment, criticism or similar scholarly purposes are not prohibited herein.<br /><br />
            D. Users may not do anything to restrict or inhibit any other User's access to or use of Street Library and the Electronic Product(s).<br /><br />
            E. If a User refuses or fails to abide by these Terms of Use or violates any other terms of this Agreement, Street Library reserves the right in its sole discretion to suspend or terminate their access to Street Library and the Electronic Products immediately without notice, in addition to any other available remedies.<br /><br />
            F. Users who wish to engage in text and data mining of the Electronic Products for non-commercial, scholarly research purposes may do so only by using Street Library and consenting to Street Library’s Text and Data Mining Agreement. Street Library reserves all rights to make reproductions and extractions for text and data mining. By agreeing to these Terms of Use, you acknowledge and agree to Street Library’s reservation of text and data mining rights. Subject to the foregoing reservation, Street Library does not restrict any statutory text and data mining rights held by User under applicable legislation.<br /><br />
            </p>
            <h4>Additional Terms</h4>
            <p>
                Street Library is not responsible for any charges associated with accessing Street Library and the Electronic Products, including, but not limited to, any computer equipment, telephone or internet connections and access software.<br /><br />
                Upon payment of an invoice, the products in the invoice are covered by the Customer’s License on the terms and conditions therein.<br /><br />
                Street Library may modify any of these terms and conditions at any time by providing notice on Street Library. A User’s continued use of Street Library shall be conclusively deemed acceptance of such modification.<br /><br />
                Street Library may provide links to third-party websites. Where such links exist, Street Library disclaims all responsibility and liability for the content of such third-party websites. Users assume the sole responsibility for the accessing of third-party websites and the use of any content appearing on such websites
            </p>
            <h4>Privacy and Data Protection Policy</h4>
            <p>Street Library recognizes the importance of protecting the information it collects in the operation of Street Library and will act in compliance with the Privacy Policy posted on website.</p>

            <h4>Refunds/Cancellations</h4>
            <p>Once the seats are confirmed, the same can not be refunded. All Services are non refundable.</p>
        </div>
    )
}

export default TermsOfUse
