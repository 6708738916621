import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../App';

function PreSelectSeat() {
    const classes = useStyles();
    const context = useContext(UserContext);
    const userName = context.state.fname;

    return (
        <div className={classes.container}>
            <div className={classes.firstLine} >Hey <span className={classes.bold}>{userName.toUpperCase()}</span>, Now You are the member of Street Library
            <br />Select Your Seat ASAP.</div>
            <Link to="/selectseat">
                <Button variant="contained" size="large" color="secondary" className={classes.btn}>select Seat Now </Button>
            </Link>
        </div>
    )
}

const useStyles = makeStyles({
    container: {
        fontSize: '35px',
        fontWeight: 500,
        fontFamily: "Montserrat",
        // marginTop: '130px',
        lineHeight: 1.6,
        textAlign: 'center',
        '@media (max-Width: 600px)': {
            margin: '0 10px',
            fontSize: '22px'
        }
    },
    bold: {
        fontWeight: 900
    },
    btn: {
        backgroundImage: 'linear-gradient(to right, #ff4b1f 0%, #ff9068  51%, #ff4b1f  100%)',
        margin: '10px',
        padding: '15px 45px',
        textAlign: 'center',
        transition: '0.5s',
        color: 'white',
        backgroundSize: '200% auto',
        boxShadow: '0 0 20px #eee',
        borderRadius: '10px',
        marginTop: '80px',
        '&:hover': {
            color: '#fff',
            backgroundPosition: 'right center',
            textDecoration: 'none'
        }
    }

})

export default PreSelectSeat

