import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import RefundResponse from './RefundResponse';

export default function RefundBox({ style }) {
    const [open, setOpen] = React.useState(false);
    const [email, setEmail] = React.useState('');
    const [response, setResponse] = React.useState('');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    async function handleRefund() {
        try {
            const res = await axios.post('/api/admin/refund', { email });
            if (res.data.status === 'success') {
                setResponse('User removed Successfully.')
            }
        } catch (error) {
            if (error.response.data.message) {
                setResponse(error.response.data.message);
            } else if (error.response.message) {
                setResponse(error.response.message);
            } else {
                setResponse("Please Check Your internet Connection");
            }
        }
    }

    return (
        <div>
            {response.length > 0 && <RefundResponse response={response} close={handleClose} />}
            <Button variant="contained" color="primary" className={style} onClick={handleClickOpen}>
                terminate user
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Terminate User</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Once you terminate a user, user seat become free(if have a seat) and payment related details will be removed.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleRefund} color="primary">
                        Terminate
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
