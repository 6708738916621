import React, { createContext, useReducer } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Navbar from './components/navbar/Navbar';
import AdminScreen from './screens/adminScreen/AdminScreen';
import SingleSeatDetail from './screens/adminScreen/SingleSeatDetail';
import BookSeatScreen from './screens/bookSeatScreen/BookSeatScreen';
import ContactScreen from './screens/contactScreen/ContactScreen';
import HomeScreen from './screens/homeScreen/HomeScreen';
import LoginScreen from './screens/loginScreen/LoginScreen';
import SignupScreen from './screens/signupScreen/SignupScreen';
import UserScreen from './screens/userScreen/UserScreen';
import NotificationScreen from './screens/notificationScreen/NotificationScreen';
import OfflineUserScreen from './screens/offlineUserScreen/OfflineUserScreen';
import ForgotPasswordScreen from './screens/forgotpasswordScreen/ForgotPasswordScreen';
import ResetPasswordScreen from './screens/resetPasswordScreen/ResetPasswordScreen';
import TermsOfUse from './screens/TermsOfUse';
import PrivacyPolicy from './screens/PrivacyPolicy';
import AboutUs from './screens/AboutUs';
import { initialState, reducer } from './reducer/UseReducer'
import './App.css';

export const UserContext = createContext()

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ state, dispatch }}>
      <Router>
        <Navbar />
        <Switch>
          {/* Navbar component */}
          <Route path="/" exact component={HomeScreen} />
          <Route path="/contactus" exact component={ContactScreen} />
          <Route path="/notification" exact component={NotificationScreen} />
          <Route path="/login" exact component={LoginScreen} />
          <Route path="/signup" exact component={SignupScreen} />

          {/* Password reset and termsAndCondition */}
          <Route path="/forgotpassword" exact component={ForgotPasswordScreen} />
          <Route path="/resetpassword" exact component={ResetPasswordScreen} />
          <Route path="/termsAndCondition" exact component={TermsOfUse} />
          <Route path="/PrivacyPolicy" exact component={PrivacyPolicy} />
          <Route path="/aboutUs" exact component={AboutUs} />

          {/* User Screen */}
          <Route path='/user' exact component={UserScreen} />
          <Route path="/selectseat" exact component={BookSeatScreen} />

          {/* Admin Screen */}
          <Route path="/admin" exact component={AdminScreen} />
          <Route path="/admin/seat/:seatNum" exact component={SingleSeatDetail} />
          <Route path="/admin/offlineuser" exact component={OfflineUserScreen} />
        </Switch>
        <Footer />
      </Router>
    </UserContext.Provider>
  );
}

export default App;
