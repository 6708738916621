import React, { useState, useContext } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import MessageBox from './MessageBox';
import { useHistory } from 'react-router-dom'
import { UserContext } from '../App';

export default function FormDialog({ seatNum, closeDialogForm }) {
    const [open, setOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [setSeatMsg, setSetSeatMsg] = useState('');
    const context = useContext(UserContext);
    const history = useHistory();

    function handleClose() {
        closeDialogForm();
    }


    async function submitHandler() {
        if (email.length === 0) {
            return;
        }

        try {
            const res = await axios(`/api/setseat/${email}/${seatNum}`)
            if (res.data.success) {
                setSetSeatMsg('Seat is set to user successfully 👍')
            }
            setOpen(false);
        } catch (error) {
            if (error.response.status === 401) {
                context.dispatch({ type: 'USER', payload: {} });
                history.push('/login');
            }
            if (error.response.data.message) {
                setSetSeatMsg(error.response.data.message);
            } else if (error.response.message) {
                setSetSeatMsg(error.response.message);
            } else {
                setSetSeatMsg("Please Check Your internet Connection");
            }
            setOpen(false);
        }
    }

    return (
        <div>
            {setSeatMsg.length !== 0 && <MessageBox msg={setSeatMsg} reload />}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Set Seat</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter User Email Id Below, To Allocate This Seat To User.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="User Email Address"
                        type="text"
                        fullWidth
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button color="primary" onClick={submitHandler}>Confirm</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
