import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import backCover from '../../Assets/abc.jpg';
import Member from '../../components/Member';
import PreSelectSeat from '../../components/PreSelectSeat';
import PaymentMethods from '../../components/PaymentMethods';
// import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../App';


function UserScreen() {
    const { state, dispatch } = useContext(UserContext);

    const [freeSeats, setFreeSeats] = useState(0);
    const [user, setUser] = useState({});
    const [loading, setLoading] = useState(true);
    // const [errorMsg, setErrorMsg] = useState('');
    const history = useHistory();

    const classes = useStyles();
    useEffect(() => {
        async function fetchData() {
            try {
                const res = await axios(`/api/user`)
                setFreeSeats(res.data.freeSeats);
                setUser(res.data.user);
                dispatch({ type: 'USER', payload: res.data.user });
                setLoading(false)
            } catch (error) {
                // console.log(error.response);
                dispatch({ type: 'USER', payload: {} });
                history.replace('/login');
                return;
            }
        }
        fetchData();
        // eslint-disable-next-line
    }, [])
    return (
        <div className={classes.userContainer}>
            {/* {errorMsg.length !== 0 && <Alert severity="error" variant="filled" className={classes.alert} >{errorMsg}</Alert>} */}
            {
                loading ? <div className={classes.loading}><CircularProgress size="10rem" /> </div> : (
                    <>
                        {freeSeats === 0 && !user.isPaid && !user.haveSeat &&
                            <div className={classes.NoSeat}>Sorry {user.fname.toUpperCase()}, Right now all seats are occupied.<br /> Please check after few days. </div>
                        }
                        {freeSeats !== 0 && !user.isPaid && !user.haveSeat && <PaymentMethods userDetails={user} />}
                        {user.isPaid && !user.haveSeat && <PreSelectSeat userDetails={user} />}
                        {user.isPaid && user.haveSeat && <Member userDetails={state} />}
                    </>
                )
            }
        </div>
    )
}


const useStyles = makeStyles({
    userContainer: {
        height: 'calc(80vh - 80px)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url(${backCover})`,
    },
    loading: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    NoSeat: {
        fontSize: '30px',
        textAlign: 'center',
        '@media (max-Width: 600px)': {
            fontSize: '25px',
            margin: '0 6px'

        },

    }
})

export default UserScreen
