import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';

export default function AddNotification({ closeAddNoti, userDetails }) {
    const [open, setOpen] = useState(true);
    const [notification, setNotification] = useState('');
    const [resMsg, setResMsg] = useState('');

    function handleClose() {
        closeAddNoti();
    }

    async function submitHandler() {
        if (notification.length === 0) {
            return;
        }
        try {
            const res = await axios.post('/api/post/notification', { message: notification });
            if (res.data.success) {
                setResMsg('Notification Add Successfully 👍');
            }
            setOpen(false);
        }
        catch (error) {
            if (error.response.data.message) {
                setResMsg(error.response.data.message);
            } else if (error.response.message) {
                setResMsg(error.response.message);
            } else {
                setResMsg("Please Check Your internet Connection");
            }
            setOpen(false)
        }

    }

    return (
        <div>
            { resMsg.length !== 0 && <MessageBox msg={resMsg} reload />}
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth >
                <DialogTitle id="form-dialog-title"  >Add Notification</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Notification Message"
                        type="text"
                        fullWidth
                        required
                        value={notification}
                        onChange={(e) => setNotification(e.target.value)} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button color="primary" onClick={submitHandler}>Post</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

