export const initialState = JSON.parse(window.localStorage.getItem('user')) || {};



export function reducer(state, action) {
    if (action.type === 'USER') {
        window.localStorage.setItem('user', JSON.stringify(action.payload));
        return action.payload;
    }
    return state;
}