import React from 'react'
import { showSeats } from '../bookSeatScreen/seats';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

function AdminSeatsArrangement({ seatsStatus, userStatus }) {
    // console.log(seatsStatus, userStatus);
    const classes = useStyles();
    const history = useHistory();
    function onSeatClickHandler(seatNum) {
        history.push(`/admin/seat/${seatNum}`);
    }
    return (
        <div className="seats-container">
            <div className="wash-room-container">
                <div className="wash-room">
                    Wash Room
                    </div>
            </div>
            <div className="row0-container">
                {showSeats.row0.map(seat =>
                    <div className=
                        {`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row0 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row1-container" >
                {showSeats.row1.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row1 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="counter-container">
                <div className="counter">
                    Counter
                    </div>
            </div>
            <div className="row10-container">
                {showSeats.row10.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row10 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row9-container">
                {showSeats.row9.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row9 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row8-container">
                {showSeats.row8.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row8 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row7-container">
                {showSeats.row7.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row7 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row6-container">
                {showSeats.row6.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row6 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row5-container">
                {showSeats.row5.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row5 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row4-container">
                {showSeats.row4.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row4 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row3-container">
                {showSeats.row3.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row3 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
            <div className="row2-container">
                {showSeats.row2.map(seat =>
                    <div className={`${seatsStatus[seat.seatNum - 1] && userStatus[seat.seatNum - 1] && classes.occupied} ${seatsStatus[seat.seatNum - 1] && !userStatus[seat.seatNum - 1] && classes.blocked} row2 row`} key={seat.seatNum}
                        onClick={() => onSeatClickHandler(seat.seatNum)}>{seat.seatNum}</div>)
                }
            </div>
        </div>
    )
}

const useStyles = makeStyles({
    occupied: {
        background: 'black',
        color: 'white',
        '&:hover': {
            cursor: 'pointer',
            background: 'black'
        }
    },
    blocked: {
        background: 'grey',
        color: 'white',
        '&:hover': {
            cursor: 'pointer',
            background: 'grey'
        }
    }
});

export default AdminSeatsArrangement
