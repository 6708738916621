
import React, { useState, useContext, useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import Alert from '@material-ui/lab/Alert';
import { CircularProgress } from '@material-ui/core';
import axios from 'axios';
import './LoginScreen.css'
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.street-library.com/">
                Street Library
      </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    root: {
        height: '90vh',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        width: '75px',
        height: '75px',
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    alert: {
        margin: '10px 0',
        padding: '0px 15px',
        '@media (max-Width: 400px)': {
            fontSize: '11px'
        }
    },
}));

export default function LoginScreen() {
    const { state, dispatch } = useContext(UserContext);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const classes = useStyles();
    const history = useHistory();



    useEffect(() => {
        if (state.fname) {
            history.push('/');
        }
        // eslint-disable-next-line
    }, [])


    async function submitHandler(e) {
        e.preventDefault();

        setLoading(true);
        try {
            const res = await axios.post('/api/login', { email, password });
            setLoading(false);
            setErrorMsg('');
            setEmail('');
            setPassword('');
            dispatch({ type: "USER", payload: res.data.user })
            // history.push('/');
            res.data.user.isAdmin ? history.push('/admin') : history.push('/user');
        }
        catch (error) {
            if (error.response.data.message) {
                setErrorMsg(error.response.data.message);
            } else if (error.response.message) {
                setErrorMsg(error.response.message);
            } else {
                setErrorMsg("Please Check Your internet Connection");
            }
            setLoading(false);
            setEmail('');
            setPassword('');
        }
    }


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={false} md={7} className="image" />
            <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <i className="fas fa-users"></i>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log In
                    </Typography>
                    {errorMsg.length !== 0 && <Alert severity="error" variant="filled" className={classes.alert} >{errorMsg}</Alert>}
                    {loading ?
                        <div className={classes.loading}><CircularProgress size="10rem" /> </div>
                        : (
                            <form className={classes.form} onSubmit={submitHandler}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Email Address"
                                    name="email"
                                    autoComplete="email"
                                    autoFocus
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)} />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    id="password"
                                    autoComplete="current-password"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <div onClick={() => setShowPassword(!showPassword)} className="eye-btn" >
                                                    {showPassword ? <i className="fas fa-eye"></i> : <i className="fas fa-eye-slash"></i>}
                                                </div>
                                            </InputAdornment>
                                        ),
                                    }}
                                    onChange={e => setPassword(e.target.value)}
                                    value={password.password} />
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" />}
                                    label="Remember me"
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}>
                                    Log In
                        </Button>
                                <Grid container>
                                    <Grid item xs>
                                        <Link href="/forgotpassword" variant="body2">Forgot password?</Link>
                                    </Grid>
                                    <Grid item>
                                        <Link href="/signup" variant="body2">
                                            {"Don't have an account?"}
                                        </Link>
                                    </Grid>
                                </Grid>
                                <Box mt={5}>
                                    <Copyright />
                                </Box>
                            </form>
                        )}
                </div>
            </Grid>
        </Grid>
    );
}