import React, { useState, useEffect } from 'react'
import { Link as Scroll } from 'react-scroll';
import './HomeScreen.css';
import Collapse from '@material-ui/core/Collapse';
import features from '../../components/Features';
import ImgCard from '../../components/ImgCard';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';


function HomeScreen() {
  const [checked, setChecked] = useState(false)
  const [checked2, setChecked2] = useState(false);

  window.addEventListener('scroll', () => {
    if (window.scrollY >= 250) {
      setChecked2(true);
    }
  })

  useEffect(() => {
    setChecked(true);
  }, [])
  return (
    <div className="homeScreen-container" id="header">
      <div className="homeScreen">
        <Collapse
          in={checked}
          style={{ transformOrigin: '0 0 0' }}
          {...(checked ? { timeout: 1700 } : {})}>
          <h1 className="homeScreen-title">Welcome  to<br /><span>Street library.</span></h1>
        </Collapse>
        <Scroll to="libFeatures" activeClass="active" smooth={true} onClick={() => setChecked2(true)} >
          {/* <i className="fas fa-caret-down"></i> */}
          <ArrowDropDownRoundedIcon
            fontSize="large"
            sx={{
              color: 'white',
              fontSize: '65px',
              cursor: 'pointer',
              '&:hover': {
                cursor: 'pointer',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '100%',
              }
            }}
          />
        </Scroll>
      </div>
      <MessengerCustomerChat
        pageId='108830208045597'
        appId='2938974356386157'
      />
      <div id="libFeatures" className="features-container">
        {
          features.map(feature => <ImgCard key={feature.imageUrl} feature={feature} checked={checked2} />)
        }
      </div>
    </div>
  )
}

export default HomeScreen
