import React, { useEffect, useState, useContext } from 'react'
import axios from 'axios';
import './BookSeactScreen.css';
import SeatConfirmation from '../../components/SeatConfirmation';
import Alert from '@material-ui/lab/Alert';
import SeatArrangement from './SeatArrangement';
import { UserContext } from '../../App';
import { useHistory } from 'react-router-dom';


function BookSeatScreen() {
    const [loading, setLoading] = useState(false);
    const [confirmSeat, setConfirmSeat] = useState(false);
    const [selectedSeatNum, setSelectedSeatNum] = useState(0);
    const [seatsStatus, setSeatsStatus] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const history = useHistory();
    const context = useContext(UserContext);
    const haveSeat = context.state ? context.state.haveSeat : false;
    const isPaid = context.state ? context.state.isPaid : false;
    const isAdmin = context.state ? context.state.isAdmin : false;


    if (!(isPaid === true && haveSeat === false) || isAdmin) {
        history.push('/');
    }


    function onSeatClickHandler(seatNum) {
        if (!seatsStatus[seatNum - 1]) {
            setSelectedSeatNum(seatNum);
            setConfirmSeat(true);
        }
    }

    function cancelConfirmSeatHandler() {
        setConfirmSeat(false);
    }

    function Error(data) {
        setErrorMsg(data);
    }

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const res = await axios('/api/seats');
                setSeatsStatus(res.data);
                setLoading(false);

            } catch (error) {
                if (error.response.data.message) {
                    setErrorMsg(error.response.data.message);
                } else if (error.response.message) {
                    setErrorMsg(error.response.message);
                } else {
                    setErrorMsg("Please Check Your internet Connection");
                }
                setLoading(false);
            }
        }
        fetchData();
    }, [])

    return (
        <div className="book-seat">
            {confirmSeat && <SeatConfirmation seatNum={selectedSeatNum} cancelSeat={cancelConfirmSeatHandler} Error={Error} />}
            <h1>Select Your Seat Now</h1>
            <div className="seat-details">
                <div className="taken" /><h3 className="taken-text">Occupied</h3>
                <div className="free" /><h3>Available</h3>
            </div>
            {errorMsg.length !== 0 && <Alert severity="error" variant="filled" >{errorMsg}</Alert>}
            {loading ? <div>Loading...</div> : (
                <SeatArrangement seatsStatus={seatsStatus} onSeatClickHandler={onSeatClickHandler} />
            )}
        </div>
    )
}

export default BookSeatScreen
