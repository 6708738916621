import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import PayButton from './PayButton';

function Member({ userDetails }) {
    const date = new Date(userDetails.payDate)
    date.setDate(date.getDate() + 30);
    const classes = useStyles();
    return (
        <div className={classes.memberContainer}>
            <div className={classes.firstLine} >Hello <span className={classes.bold}>{userDetails.fname.toUpperCase()}</span>, Hope You are doing well.</div>
            <div className={classes.small}>(Your Seat Number is : {userDetails.seatNum})</div>
            <div className={classes.sline}> <span className={classes.bold}>Your Subscription Will End On: </span>{date.toString().slice(0, 15)}</div>
            {
                userDetails.isLessThanTwoDaysLeft &&
                <div className={classes.pay}>
                    Subscription ends in few days, pay if you want to continue
                    <PayButton userDetails={userDetails} />
                </div>
            }
        </div>
    )
}

const useStyles = makeStyles({
    memberContainer: {
        fontSize: '35px',
        fontWeight: 500,
        fontFamily: "Montserrat",
        lineHeight: 1.6,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '@media (max-Width: 830px)': {
            fontSize: '25px'
        },
        '@media (max-Width: 600px)': {
            margin: '0 6px',
            fontSize: '20px'
        },
        '@media (max-Width: 450px)': {
            fontSize: '18px'
        },
    },
    bold: {
        fontWeight: 900
    },
    sline: {
        '@media (max-Width: 500px)': {
            display: 'flex',
            flexDirection: 'column'
        },
    },
    small: {
        fontSize: '25px',
        '@media (max-Width: 630px)': {
            fontSize: '16px'
        }

    },
    pay: {
        marginTop: '60px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
    }
})

export default Member
