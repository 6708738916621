export const showSeats = {
    row0: [
        {
            seatNum: 31
        },
        {
            seatNum: 32
        },
        {
            seatNum: 33
        },

    ],
    row1: [
        {
            seatNum: 30
        },
        {
            seatNum: 29
        },
        {
            seatNum: 28
        },
    ],
    row2: [
        {
            seatNum: 27
        },
        {
            seatNum: 26
        },
        {
            seatNum: 25
        },
        {
            seatNum: 24
        },
        {
            seatNum: 23
        },
        {
            seatNum: 22
        },
        {
            seatNum: 21
        },
        {
            seatNum: 20
        },
        {
            seatNum: 19
        },
        {
            seatNum: 18
        },
        {
            seatNum: 17
        },
        {
            seatNum: 16
        },
        {
            seatNum: 15
        },
        {
            seatNum: 14
        },
        {
            seatNum: 13
        },

    ],
    row3: [
        {
            seatNum: 34
        },
        {
            seatNum: 35
        },
        {
            seatNum: 36
        },
        {
            seatNum: 37
        },
        {
            seatNum: 38
        },
        {
            seatNum: 39
        },
        {
            seatNum: 40
        },
        {
            seatNum: 41
        },
        {
            seatNum: 42
        },

    ],
    row4: [
        {
            seatNum: 51
        },
        {
            seatNum: 50
        },
        {
            seatNum: 49
        },
        {
            seatNum: 48
        },
        {
            seatNum: 47
        },
        {
            seatNum: 46
        },
        {
            seatNum: 45
        },
        {
            seatNum: 44
        },
        {
            seatNum: 43
        }
    ],
    row5: [
        {
            seatNum: 7
        },
        {
            seatNum: 8
        },
        {
            seatNum: 9
        },
        {
            seatNum: 10
        },
        {
            seatNum: 11
        },
        {
            seatNum: 12
        },
    ],
    row6: [
        {
            seatNum: 1
        },
        {
            seatNum: 2
        },
        {
            seatNum: 3
        },
        {
            seatNum: 4
        },
        {
            seatNum: 5
        },
        {
            seatNum: 6
        },
    ],
    row7: [
        {
            seatNum: 64
        },
        {
            seatNum: 65
        },
        {
            seatNum: 66
        },
    ],
    row8: [
        {
            seatNum: 69
        },
        {
            seatNum: 68
        },
        {
            seatNum: 67
        },
    ],
    row9: [
        {
            seatNum: 57
        },
        {
            seatNum: 56
        },
        {
            seatNum: 55
        },
        {
            seatNum: 54
        },
        {
            seatNum: 53
        },
        {
            seatNum: 52
        },
    ],
    row10: [
        {
            seatNum: 63
        },
        {
            seatNum: 62
        },
        {
            seatNum: 61
        },
        {
            seatNum: 60
        },
        {
            seatNum: 59
        },
        {
            seatNum: 58
        },
    ]
}