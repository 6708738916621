import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withRouter } from 'react-router';

function MessageBox({ msg, reload, resetpassword, history, redirectLogin }) {
    const [open, setOpen] = React.useState(true);


    function handleClose() {
        setOpen(false);
        if (reload) {
            window.location.reload();
        }
        if (resetpassword) {
            history.push('/resetpassword');
        }
        if (redirectLogin) {
            history.push('/login')
        }

    };

    return (
        <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" fullWidth>
            <DialogTitle id="alert-dialog-title">{msg}</DialogTitle>
            <DialogActions>
                <Button onClick={handleClose} color="primary" autoFocus>OK</Button>
            </DialogActions>
        </Dialog>
    );
}


export default withRouter(MessageBox);