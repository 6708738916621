import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import axios from 'axios';
import MessageBox from '../../components/MessageBox';
import { CircularProgress } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    container: {
        height: 'calc(80vh - 80px)',
        display: 'flex',
        alignItems: 'center'
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function ResetPasswordScreen() {
    const [otp, setOtp] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [response, setResponse] = useState('');
    const [loading, setLoading] = useState('');
    const classes = useStyles();

    let passwordNotMatch;

    if (confirmPassword.length !== 0 && password !== confirmPassword) {
        passwordNotMatch = true;
    } else {
        passwordNotMatch = false;
    }

    async function submitHandler(e) {
        e.preventDefault();

        if (otp.length === 0 || password.length === 0 || confirmPassword === 0) {
            return;
        }
        try {
            setLoading(true);
            const res = await axios.patch(`api/resetpassword/${otp}`, { password });
            if (res.data.status === 'success') {
                setResponse('Password Changed Successfully');
                setLoading(false);
            }
        } catch (error) {
            if (error.response.data.message) {
                setResponse(error.response.data.message);
            } else if (error.response.message) {
                setResponse(error.response.message);
            } else {
                setResponse("Please Check Your internet Connection");
            }
            setLoading(false);
        }

    }

    return (
        <div className={classes.container}>
            <Container component="main" maxWidth="xs"  >
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <i className="fas fa-unlock-alt"></i>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Reset Password
                </Typography>
                    {response.length !== 0 && <MessageBox msg={response} redirectLogin />}
                    {
                        loading ? <div className={classes.loading}><CircularProgress size="10rem" /> </div> : (
                            <form className={classes.form} noValidate>
                                <TextField
                                    autoFocus
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="OTP"
                                    label="Enter OTP"
                                    type="text"
                                    id="OTP"
                                    value={otp}
                                    onChange={(e) => setOtp(e.target.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Password"
                                    type="password"
                                    id="password"
                                    error={password.length !== 0 && password.length < 8 && true}
                                    helperText={password.length !== 0 && password.length < 8 && "Password Must Contain Atleast 8 Characters."}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Confirm Password"
                                    type="password"
                                    id="password"
                                    error={passwordNotMatch && true}
                                    helperText={passwordNotMatch && "Passwords doest not match."}
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                    onClick={submitHandler}>
                                    Confirm
                        </Button>
                            </form>
                        )
                    }
                </div>
            </Container>
        </div>
    );
}